import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICustomFile } from 'file-input-accessor';
import { Moment } from 'moment';
import { combineLatest } from 'rxjs';
import { mergeMap, takeLast } from 'rxjs/operators';
import { DropDownModel, FileParameter, InvitedAccountClient, InvitedAccountModel, UploadResponse } from '@volt/api';
import { RETAILER_CONFIG, RetailerConfigType } from '../../../retailer-config';
import { VoltValidators } from '../../shared/volt-validators';
import { RetailLinkRegistrationService } from '../services/retaillink-registration.service';
import { StateEnum } from './models/StateEnum';
import { RetailLinkControlModalComponent } from './retaillink-control-modal/retaillink-control-modal.component';
import { SelectItem } from 'primeng/api';
import { DynamicDialogService } from '@volt/shared/components/dialogs/dynamic-dialog/dynamic-dialog.service';

@Component({
  selector: 'retaillink-registration-form',
  templateUrl: './retaillink-registration.component.html',
  styleUrls: ['./retaillink-registration.component.scss', '../../../assets/core/core.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RetailLinkRegistrationComponent implements OnInit {
  public registrationGroup: UntypedFormGroup;
  public errorMessage: string;
  public working = false;
  public error: string = null;
  public fieldTypeDropModel: DropDownModel[];
  public certificationDropModel: DropDownModel[];
  public yesNoDropDownModel: SelectItem[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public data: InvitedAccountModel;
  public accountNumber: string;
  public companyName: string;
  public abbreviation: string;
  public invitedName: string;
  public invitedEmail: string;
  public invitedNote: string;
  public aNote: string;
  public invitedAccountId = 0;
  public fieldGroupType: string;
  public certification: string;
  public firstName: ' ';
  public lastName: ' ';
  public email1: string;
  public phoneNumber: '';
  public technicalPhone: '';
  public supportPhone: '';
  public billingContactName: string;
  public billingEmail: string;
  public billingPhone: '';
  public billingStreetAddress: ' ';
  public billingStreetAddress2: ' ';
  public billingCity: ' ';
  public billingState: ' ';
  // tslint:disable-next-line:no-inferrable-types
  public billingCountry: string = '';
  public billingZipCode: '';
  public shippingContactName: string;
  public shippingStreetAddress: ' ';
  public shippingStreetAddress2: ' ';
  public shippingCity: ' ';
  public shippingState: ' ';
  // tslint:disable-next-line:no-inferrable-types
  public shippingCountry: string = '';
  public shippingZipCode: '';
  public userZipCode: '';
  // tslint:disable-next-line:no-inferrable-types
  public userCountry: string = '';
  public files: ICustomFile[] = [];
  public stateDropDownModel: DropDownModel[];
  public countryDropDownModel: DropDownModel[];
  public photoValues: string[];
  public emailExist = false;
  public emailMsg = false;
  public zipCodeMsg = false;
  public zipBillingCodeMsg = false;
  public zipShippingCodeMsg = false;
  public zipUserCodeMsg = false;
  public zipBillingCanMsg = false;
  public zipShippingCanMsg = false;
  public zipUserCanMsg = false;
  public invitedDate: Moment;
  public lastInvitedDate: Moment;
  public loading = false;
  public fileCtrl = this._fb.control(null, Validators.required);
  public invalidControl: string[] = [];
  public marginValue = '1200px';

  // tslint:disable-next-line:prefer-const
  public websiteRegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(
    private _fb: UntypedFormBuilder,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _dynamicDialogService: DynamicDialogService,
    private _retailLinkRegistrationService: RetailLinkRegistrationService,
    private _invitedAccountClient: InvitedAccountClient,
    @Inject(RETAILER_CONFIG) public readonly retailerConfig: RetailerConfigType,
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.getStateDropDownList();
    this.getCountryDropDownList();
  }

  public getStateDropDownList() {
    this._retailLinkRegistrationService.getStateDropDownList().subscribe(
      (data: any) => {
        this.stateDropDownModel = data;
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public getCountryDropDownList() {
    this._retailLinkRegistrationService.getCountryDropDownList().subscribe(
      (data: any) => {
        this.countryDropDownModel = data;
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  public submitRegistration() {
    const checkResponse = this.checkControlValidation();

    if (!checkResponse) {
      return;
    }

    this.loading = true;
    const invitedAccount = new InvitedAccountModel();

    invitedAccount.invitedCompany = this.registrationGroup.get('companyName').value.toString().trim();

    invitedAccount.aabbrev =
      this.registrationGroup.get('abbreviation').value === '' ? null : this.registrationGroup.get('abbreviation').value;

    invitedAccount.aname = this.registrationGroup.get('companyName').value.toString().trim();
    invitedAccount.accountNumber = this.registrationGroup.get('accountNumber').value;
    invitedAccount.fName = this.registrationGroup.get('firstName').value;
    invitedAccount.invitedName = this.registrationGroup.get('firstName').value;
    invitedAccount.lName = this.registrationGroup.get('lastName').value;
    invitedAccount.email1 = this.registrationGroup.get('adminEmail').value;
    invitedAccount.invitedEmail = this.registrationGroup.get('adminEmail').value;
    invitedAccount.pPhone = this.registrationGroup.get('phoneNumber').value;
    invitedAccount.invitedAccountId = 0;
    invitedAccount.fieldGroupType = '';
    invitedAccount.invitedCourse = '';
    invitedAccount.invitedNote = '';
    //     invitedAccount.invitedDate = this.invitedDate;
    //   invitedAccount.lastInvitedDate = this.lastInvitedDate;

    invitedAccount.billAttnToFname = this.registrationGroup.get('billingContactName').value;
    invitedAccount.billingEmail = this.registrationGroup.get('billingEmail').value;
    invitedAccount.bphone = this.registrationGroup.get('billingPhone').value;
    invitedAccount.baddress1 = this.registrationGroup.get('billingStreetAddress').value;
    invitedAccount.baddress2 = this.registrationGroup.get('billingStreetAddress2').value;
    invitedAccount.bcity = this.registrationGroup.get('billingCity').value;
    invitedAccount.bstate = this.registrationGroup.get('billingState').value;
    invitedAccount.bcountry = this.registrationGroup.get('billingCountry').value;
    invitedAccount.bzip = this.registrationGroup.get('billingZipCode').value;

    invitedAccount.shipAttnTo = this.registrationGroup.get('shippingContactName').value;
    invitedAccount.saddress1 = this.registrationGroup.get('shippingStreetAddress').value;
    invitedAccount.saddress2 = this.registrationGroup.get('shippingStreetAddress2').value;
    invitedAccount.scity = this.registrationGroup.get('shippingCity').value;
    invitedAccount.sstate = this.registrationGroup.get('shippingState').value;
    invitedAccount.scountry = this.registrationGroup.get('shippingCountry').value;
    invitedAccount.szip = this.registrationGroup.get('shippingZipCode').value;

    invitedAccount.itcontactEmail = this.registrationGroup.get('technicalContactEmail').value;
    invitedAccount.supportEmail = this.registrationGroup.get('supportContactEmail').value;
    invitedAccount.itcontactPhone = this.registrationGroup.get('technicalPhone').value;
    invitedAccount.supportPhone = this.registrationGroup.get('supportPhone').value;
    invitedAccount.supportNote = this.registrationGroup.get('supportContactNote').value;

    invitedAccount.userSpecificEmailsForCommunication = this.registrationGroup.get('userSpecificEmails').value.value;
    invitedAccount.provideBadgeWithPhoto = this.registrationGroup.get('provideBadgeWithPhoto').value.value;
    invitedAccount.estimatedUsers = this.registrationGroup.get('estimatedUser').value;
    invitedAccount.uDirectlyEmployed = this.registrationGroup.get('userEmployed').value;
    invitedAccount.uMobileEnabled = this.registrationGroup.get('userMobileEnabled').value;

    invitedAccount.aaddress1 =
      this.registrationGroup.get('userAddressLine1').value === ''
        ? null
        : this.registrationGroup.get('userAddressLine1').value;

    invitedAccount.aaddress2 =
      this.registrationGroup.get('userAddressLine2').value === ''
        ? null
        : this.registrationGroup.get('userAddressLine2').value;

    invitedAccount.acity =
      this.registrationGroup.get('userCity').value === '' ? null : this.registrationGroup.get('userCity').value;

    invitedAccount.astate =
      this.registrationGroup.get('userState').value === '' ? null : this.registrationGroup.get('userState').value;

    invitedAccount.acountry =
      this.registrationGroup.get('userCountry').value === '' ? null : this.registrationGroup.get('userCountry').value;

    invitedAccount.azip =
      this.registrationGroup.get('userZipCode').value === '' ? null : this.registrationGroup.get('userZipCode').value;

    invitedAccount.awebsite =
      this.registrationGroup.get('userCorporateWebsite').value === ''
        ? null
        : this.registrationGroup.get('userCorporateWebsite').value;

    invitedAccount.accountId = 0;
    invitedAccount.shipDirectToRep = 0;
    invitedAccount.retailLink = true;
    invitedAccount.aNote = this.aNote;

    if (this.files && this.files.length) {
      const uploadTasks = [];

      const file: FileParameter = {
        data: this.files[0],
        fileName: this.files[0].name,
      };

      uploadTasks.push(this._invitedAccountClient.registerFileUpload(file));

      combineLatest(uploadTasks)
        .pipe(
          takeLast(uploadTasks.length),
          mergeMap((data: UploadResponse[]) => {
            const urls = data.map(d => d.data);

            this.photoValues = urls;
            invitedAccount.aimageUrl = this.photoValues[0];
            return this._invitedAccountClient.registerInvitedAccount(invitedAccount);
          }),
        )
        .subscribe(
          (response: any) => {
            if (response) {
              this.loading = false;
              this._router.navigateByUrl('registration-confirm');
            }
            this.registrationGroup.reset();
          },
          err => {
            console.error(err);
          },
        );
    } else {
      this._invitedAccountClient.registerInvitedAccount(invitedAccount).subscribe(
        (response: any) => {
          if (response) {
            this.loading = false;
            this._router.navigateByUrl('registration-confirm');
          }
        },
        err => {
          console.error(err);
        },
      );
    }
  }

  public checkEmailAlreadyExist() {
    if (this.email1 === '' || this.email1 === undefined || this.email1 === null) return (this.emailMsg = false);

    this._retailLinkRegistrationService.checkEmailAlreadyExist(this.email1, this.invitedAccountId).subscribe(
      (data: any) => {
        this.emailExist = data;
        if (data) {
          this.emailMsg = true;
        } else {
          this.emailMsg = false;
        }
      },
      (err: any) => {
        console.error(err);
      },
    );
  }

  isNumberKey(evt) {
    // tslint:disable-next-line:prefer-const
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else {
      return true;
    }
  }

  fieldSameAsProvider(event) {
    if (event.target.checked) {
      if (this.firstName !== undefined || this.lastName !== undefined) {
        this.billingContactName = this.firstName + ' ' + this.lastName;
      } else {
        this.billingContactName = '';
      }
      this.billingEmail = this.email1;
      this.billingPhone = this.phoneNumber;
    } else {
      this.billingContactName = ' ';
      this.billingEmail = ' ';
      this.billingPhone = '';
    }
  }

  fieldSameAsBilling(event) {
    if (event.target.checked) {
      if (this.billingContactName !== undefined) {
        this.shippingContactName = this.billingContactName;
      } else {
        this.shippingContactName = '';
      }
      this.shippingStreetAddress = this.billingStreetAddress;
      this.shippingStreetAddress2 = this.billingStreetAddress2;
      this.shippingCity = this.billingCity;
      this.shippingState = this.billingState;
      this.shippingCountry = this.billingCountry;
      this.shippingZipCode = this.billingZipCode;
    } else {
      this.shippingContactName = ' ';
      this.shippingStreetAddress = ' ';
      this.shippingStreetAddress2 = ' ';
      this.shippingCity = ' ';
      this.shippingState = ' ';
      this.shippingCountry = '';
      this.shippingZipCode = '';
    }
  }

  public formReset() {
    this.registrationGroup.reset();
  }

  public get companyNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('companyName');
  }

  public get abbreviationControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('abbreviation');
  }

  public get accountNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }
    return <UntypedFormControl>this.registrationGroup.get('accountNumber');
  }

  public get firstNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('firstName');
  }

  public get lastNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('lastName');
  }

  public get phoneNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('phoneNumber');
  }

  public get emailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('adminEmail');
  }

  public get biilingContactNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingContactName');
  }

  public get biilingStreetAddressControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingStreetAddress');
  }

  public get billingCityControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingCity');
  }

  public get billingZipCodeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingZipCode');
  }

  public get billingEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingEmail');
  }

  public get billingContactControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('billingPhone');
  }

  public get billingStateControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }
    return <UntypedFormControl>this.registrationGroup.get('billingState');
  }

  public get billingCountryControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }
    return <UntypedFormControl>this.registrationGroup.get('billingCountry');
  }

  public get shippingContactNameControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingContactName');
  }

  public get shippingStreetAddressControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingStreetAddress');
  }

  public get shippingCityControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingCity');
  }

  public get shippingStateControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingState');
  }

  public get shippingCountryControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingCountry');
  }

  public get shippingZipCodeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('shippingZipCode');
  }

  public get technicalContactEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('technicalContactEmail');
  }

  public get technicalContactNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('technicalPhone');
  }

  public get supportContactEmailControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('supportContactEmail');
  }

  public get supportContactNumberControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('supportPhone');
  }

  public get userZipCodeControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userZipCode');
  }

  public get fileControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('files');
  }

  public get corporateWebsiteControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }
    return <UntypedFormControl>this.registrationGroup.get('userCorporateWebsite');
  }

  public get userMobileEnabledControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userMobileEnabled');
  }

  public get userEmployedControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userEmployed');
  }

  public get userSpecificEmailForCommunicationControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('userSpecificEmails');
  }

  public get provideBadgeWithPhotoControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('provideBadgeWithPhoto');
  }

  public get estimatedUserControl(): UntypedFormControl {
    if (!this.registrationGroup) {
      return;
    }

    return <UntypedFormControl>this.registrationGroup.get('estimatedUser');
  }

  getInvitedAccountById(id) {
    this._retailLinkRegistrationService.getInvitedAccountById(id).subscribe(response => {
      this.data = response;

      if (response) {
        this.accountNumber = this.data.accountNumber;
        this.companyName = this.data.invitedCompany;
        this.invitedName = this.data.invitedName;
        this.invitedAccountId = this.data.invitedAccountId;
        this.fieldGroupType = this.data.fieldGroupType;
        this.certification = this.data.invitedCourse;
        this.invitedEmail = this.data.invitedEmail;
        this.email1 = this.data.invitedEmail;
        this.invitedNote = this.data.invitedNote;
        this.aNote = this.data.aNote;
      }
    });
  }

  public onBlurPhoneNumber() {
    if (this.phoneNumber !== undefined) {
      const flag = this.replacePhoneNumber(this.phoneNumber);
      if (flag) {
        this.phoneNumber = '';
      }
    }

    if (this.billingPhone !== undefined) {
      const flag = this.replacePhoneNumber(this.billingPhone);
      if (flag) {
        this.billingPhone = '';
      }
    }

    if (this.technicalPhone !== undefined) {
      const flag = this.replacePhoneNumber(this.technicalPhone);
      if (flag) {
        this.technicalPhone = '';
      }
    }

    if (this.supportPhone !== undefined) {
      const flag = this.replacePhoneNumber(this.supportPhone);
      if (flag) {
        this.supportPhone = '';
      }
    }
  }

  public onBlurBillingZipCode() {
    if (this.billingZipCode !== undefined && this.billingZipCode !== '') {
      if (this.billingCountry.toString() === StateEnum.USA) {
        const flag = this.replaceZipCode(this.billingZipCode, 'Billing');
        if (flag) {
          this.billingZipCode = '';
        }
      }

      if (this.billingCountry.toString() === StateEnum.CANADA) {
        const flag = this.replaceCanadaZipCode(this.billingZipCode, 'Billing');
        if (flag) {
          this.billingZipCode = '';
        }
      }
    }
  }

  public onBlurShippingZipCode() {
    if (this.shippingZipCode !== undefined && this.shippingZipCode !== '') {
      if (this.shippingCountry.toString() === StateEnum.USA) {
        const flag = this.replaceZipCode(this.shippingZipCode, 'Shipping');
        if (flag) {
          this.shippingZipCode = '';
        }
      }

      if (this.shippingCountry.toString() === StateEnum.CANADA) {
        const flag = this.replaceCanadaZipCode(this.shippingZipCode, 'Shipping');
        if (flag) {
          this.shippingZipCode = '';
        }
      }
    }
  }

  public onBlurUserZipCode() {
    if (this.userZipCode !== undefined && this.userZipCode !== '') {
      if (this.userCountry.toString() === StateEnum.USA) {
        const flag = this.replaceZipCode(this.userZipCode, 'User');
        if (flag) {
          this.userZipCode = '';
        }
      }

      if (this.userCountry.toString() === StateEnum.CANADA) {
        const flag = this.replaceCanadaZipCode(this.userZipCode, 'User');
        if (flag) {
          this.userZipCode = '';
        }
      }
    }
  }

  // this method is used for masking validation for PhoneNumber
  public replacePhoneNumber(contactNumber: any) {
    const len = contactNumber.replace(/[^0-9]/g, '').length;

    if (len < 10) {
      return true;
    } else {
      return false;
    }
  }

  // This method is used for USA Zip Code masking
  public replaceZipCode(zipCode: any, zipName: string) {
    let temp = zipCode.trim();
    const len = zipCode.replace(/[^0-9]/g, '').length;

    if (len === 5) {
      temp = zipCode.replace(/[^0-9]/g, '').trim();
    } else {
      temp = zipCode.trim();
    }

    if (len === 0) {
      if (zipName === 'Billing') {
        this.zipBillingCodeMsg = false;
        this.billingZipCode = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCodeMsg = false;
        this.shippingZipCode = temp;
      } else if (zipName === 'User') {
        this.zipUserCodeMsg = false;
        this.userZipCode = temp;
      }

      return true;
    } else if (len < 5) {
      if (zipName === 'Billing') {
        this.zipBillingCodeMsg = true;
        this.billingZipCode = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCodeMsg = true;
        this.shippingZipCode = temp;
      } else if (zipName === 'User') {
        this.zipUserCodeMsg = true;
        this.userZipCode = temp;
      }
      return true;
    } else {
      if (zipName === 'Billing') {
        this.zipBillingCodeMsg = false;
        setTimeout(() => {
          this.billingZipCode = temp;
        }, 100);
      } else if (zipName === 'Shipping') {
        this.zipShippingCodeMsg = false;
        setTimeout(() => {
          this.shippingZipCode = temp;
        }, 100);
      } else if (zipName === 'User') {
        this.zipUserCodeMsg = false;
        setTimeout(() => {
          this.userZipCode = temp;
        }, 100);
      }
      return false;
    }
  }

  // This method is used for Canada Zip Code masking
  public replaceCanadaZipCode(zipCode: any, zipName: string) {
    const temp = zipCode.trim();
    const len = zipCode.replace(/[^0-9]/g, '').length;

    if (len === 0) {
      if (zipName === 'Billing') {
        this.zipBillingCanMsg = false;
        this.billingZipCode = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCanMsg = false;
        this.shippingZipCode = temp;
      } else if (zipName === 'User') {
        this.zipUserCanMsg = false;
        this.userZipCode = temp;
      }

      return true;
    } else if (len < 6) {
      if (zipName === 'Billing') {
        this.zipBillingCanMsg = true;
        this.billingZipCode = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCanMsg = true;
        this.shippingZipCode = temp;
      } else if (zipName === 'User') {
        this.zipUserCanMsg = true;
        this.userZipCode = temp;
      }
      return true;
    } else {
      if (zipName === 'Billing') {
        this.zipBillingCanMsg = false;
        this.billingZipCode = temp;
      } else if (zipName === 'Shipping') {
        this.zipShippingCanMsg = false;
        this.shippingZipCode = temp;
      } else if (zipName === 'User') {
        this.zipUserCanMsg = false;
        this.userZipCode = temp;
      }
      return false;
    }
  }

  resetZipBillingCodeMsg(event: any) {
    this.billingZipCode = '';
    this.zipBillingCodeMsg = false;
    this.zipBillingCanMsg = false;
  }

  resetZipShippingCodeMsg(event: any) {
    this.shippingZipCode = '';
    this.zipShippingCodeMsg = false;
    this.zipShippingCanMsg = false;
  }

  resetZipUserCodeMsg(event: any) {
    this.userZipCode = '';
    this.zipUserCodeMsg = false;
    this.zipUserCanMsg = false;
  }

  public checkControlValidation() {
    this.invalidControl = [];

    this.CheckValidationForRegistration();

    if (this.invalidControl.length > 0) {
      this._dynamicDialogService.open(RetailLinkControlModalComponent, {
        data: {
          requiredfield: this.invalidControl,
        },
      });
      return false;
    }

    return true;
  }

  // this is used to check the required field control are filled or not and valid or not
  public CheckValidationForRegistration() {
    if (!this.registrationGroup.get('adminEmail').valid) {
      this.invalidControl.push('Admin Email.');
    } else if (this.emailExist === true) {
      this.invalidControl.push('Email Already Exists.');
    }

    if (!this.registrationGroup.get('companyName').valid) {
      this.invalidControl.push('Company Name.');
    }

    if (!this.registrationGroup.get('firstName').valid) {
      this.invalidControl.push('Admin First Name.');
    }

    if (!this.registrationGroup.get('abbreviation').valid) {
      this.invalidControl.push('Abbreviation.');
    }

    if (!this.registrationGroup.get('lastName').valid) {
      this.invalidControl.push('Admin Last Name.');
    }

    if (!this.registrationGroup.get('phoneNumber').valid) {
      this.invalidControl.push('Phone Number.');
    }

    if (!this.registrationGroup.get('files').valid) {
      this.invalidControl.push('Company Logo.');
    }

    if (!this.registrationGroup.get('billingContactName').valid) {
      this.invalidControl.push('Billing Contact Name.');
    }

    if (!this.registrationGroup.get('billingEmail').valid) {
      this.invalidControl.push('Billing Email.');
    }

    if (!this.registrationGroup.get('billingPhone').valid) {
      this.invalidControl.push('Billing Phone.');
    }

    if (!this.registrationGroup.get('billingStreetAddress').valid) {
      this.invalidControl.push('Billing Street Address.');
    }

    if (!this.registrationGroup.get('billingCity').valid) {
      this.invalidControl.push('Billing City.');
    }

    if (!this.registrationGroup.get('billingState').valid) {
      this.invalidControl.push('Billing State.');
    }

    if (!this.registrationGroup.get('billingCountry').valid) {
      this.invalidControl.push('Billing Country.');
    }

    if (!this.registrationGroup.get('billingZipCode').valid) {
      this.invalidControl.push('Billing ZipCode.');
    }

    if (!this.registrationGroup.get('shippingContactName').valid) {
      this.invalidControl.push('Shipping Contact Name.');
    }

    if (!this.registrationGroup.get('shippingStreetAddress').valid) {
      this.invalidControl.push('Shipping Street Address.');
    }

    if (!this.registrationGroup.get('shippingCity').valid) {
      this.invalidControl.push('Shipping City.');
    }

    if (!this.registrationGroup.get('shippingState').valid) {
      this.invalidControl.push('Shipping State.');
    }

    if (!this.registrationGroup.get('shippingCountry').valid) {
      this.invalidControl.push('Shipping Country.');
    }

    if (!this.registrationGroup.get('shippingZipCode').valid) {
      this.invalidControl.push('Shipping ZipCode.');
    }

    if (!this.registrationGroup.get('supportContactEmail').valid) {
      this.invalidControl.push('Support Contact Email.');
    }

    if (!this.registrationGroup.get('supportPhone').valid) {
      this.invalidControl.push('Support Phone.');
    }

    if (!this.registrationGroup.get('technicalContactEmail').valid) {
      this.invalidControl.push('Technical Email.');
    }

    if (!this.registrationGroup.get('technicalPhone').valid) {
      this.invalidControl.push('Technical Phone.');
    }

    if (!this.registrationGroup.get('userCorporateWebsite').valid) {
      this.invalidControl.push('Corporate Website.');
    }

    if (!this.registrationGroup.get('userSpecificEmails').valid) {
      this.invalidControl.push(
        'Do you utilize user-specific emails they can use for communications from Volt such as password resets?',
      );
    }

    if (!this.registrationGroup.get('provideBadgeWithPhoto').valid) {
      this.invalidControl.push('Do you provide your employees with a badge that contains a picture of the user?');
    }

    if (!this.registrationGroup.get('estimatedUser').valid) {
      this.invalidControl.push('Estimated # Users');
    }

    if (!this.registrationGroup.get('userEmployed').valid) {
      this.invalidControl.push('Are Users Directly Employed?');
    }
    if (!this.registrationGroup.get('userMobileEnabled').valid) {
      this.invalidControl.push('Are Users Mobile Enabled?');
    }
  }

  private initForm() {
    //  const fileCtrl = this._fb.control(null, Validators.required);

    this.fileCtrl.valueChanges.subscribe(val => {
      this.files = val ? val : [];
    });

    this.registrationGroup = this._fb.group({
      companyName: this._fb.control('', Validators.required),
      abbreviation: this._fb.control('', Validators.required),
      accountNumber: this._fb.control(''),
      firstName: this._fb.control('', Validators.required),
      lastName: this._fb.control('', Validators.required),
      phoneNumber: this._fb.control('', Validators.required),
      adminEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      //  adminEmail: this._fb.control(''),
      billingContactName: this._fb.control('', Validators.required),
      billingEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      billingStreetAddress: this._fb.control('', Validators.required),
      billingCity: this._fb.control('', Validators.required),
      billingZipCode: this._fb.control('', Validators.required),
      billingPhone: this._fb.control('', Validators.required),
      billingStreetAddress2: this._fb.control(''),
      billingState: this._fb.control('', Validators.required),
      billingCountry: this._fb.control('', Validators.required),
      shippingContactName: this._fb.control('', Validators.required),
      shippingStreetAddress: this._fb.control('', Validators.required),
      shippingCity: this._fb.control('', Validators.required),
      shippingZipCode: this._fb.control('', Validators.required),
      shippingStreetAddress2: this._fb.control(''),
      shippingState: this._fb.control('', Validators.required),
      shippingCountry: this._fb.control('', Validators.required),
      technicalContactEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      supportContactEmail: this._fb.control('', Validators.compose([Validators.required, Validators.email])),
      technicalPhone: this._fb.control('', Validators.required),
      supportPhone: this._fb.control('', Validators.required),
      supportContactNote: this._fb.control(''),
      userSpecificEmails: this._fb.control(null, Validators.required),
      provideBadgeWithPhoto: this._fb.control(null, Validators.required),
      estimatedUser: this._fb.control('', Validators.required),
      userEmployed: this._fb.control('', Validators.required),
      userMobileEnabled: this._fb.control('', Validators.required),
      userVendorId: this._fb.control(''),
      userAddressLine1: this._fb.control(''),
      userAddressLine2: this._fb.control(''),
      userCity: this._fb.control(''),
      userState: this._fb.control(''),
      userCountry: this._fb.control(''),
      userZipCode: this._fb.control(''),
      userCorporateWebsite: this._fb.control('', Validators.compose([Validators.pattern(this.websiteRegExp)])),
      files: this.fileCtrl,
    });
  }
}

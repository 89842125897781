<v-loading-container [working]="loading" [marginTop]="marginValue">
  <div class="container-fluid dashboard-page">
    <header class="page-header">
      <h2 data-cy="supplier-registration-title" class="page title">Supplier Registration</h2>
    </header>

    <div class="row flex-xl-nowrap">
      <div *ngIf="errorMessage" class="text-danger">Unable to Supplier: {{ errorMessage }}</div>

      <div class="col-xl-12">
        <div class="">
          <form novalidate [formGroup]="registrationGroup" (submit)="submitRegistration(); (false)">
            <div class="sep-form">
              <h2 data-cy="provider-admin-section-title">Provider admin</h2>
              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="company-name-label">
                          Company Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="companyName"
                          [(ngModel)]="companyName"
                          maxlength="50"
                          formControlName="companyName"
                          required
                        />

                        <div *ngIf="companyNameControl.touched && companyNameControl.errors">
                          <span
                            *ngIf="
                              companyNameControl.touched &&
                              (companyNameControl.hasError('required') || companyNameControl.hasError('companyName'))
                            "
                            class="text-danger"
                          >
                            A valid Company Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="abbreviation-label">
                          Abbreviation
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="abbreviation"
                          [(ngModel)]="abbreviation"
                          maxlength="20"
                          formControlName="abbreviation"
                          required
                        />

                        <div *ngIf="abbreviationControl.touched && abbreviationControl.errors">
                          <span
                            *ngIf="
                              abbreviationControl.touched &&
                              (abbreviationControl.hasError('required') || abbreviationControl.hasError('abbreviation'))
                            "
                            class="text-danger"
                          >
                            A valid Abbreviation is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="walmart-supplier-number-label">
                          Supplier Number
                          <br />
                          (assigned by {{ retailerConfig.retailer }})
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="accountNumber"
                          [(ngModel)]="accountNumber"
                          (keypress)="isNumberKey($event)"
                          maxlength="50"
                          formControlName="accountNumber"
                        />
                        <span data-cy="walmart-supplier-number-info" style="font-size: 10px">
                          This is your company's supplier number assigned by the {{ retailerConfig.retailer }}.
                        </span>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="admin-first-name-label">
                          Admin First Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="firstName"
                          maxlength="50"
                          [(ngModel)]="firstName"
                          formControlName="firstName"
                          required
                        />

                        <div *ngIf="firstNameControl.touched && firstNameControl.errors">
                          <span
                            *ngIf="
                              firstNameControl.touched &&
                              (firstNameControl.hasError('required') || firstNameControl.hasError('firstName'))
                            "
                            class="text-danger"
                          >
                            A valid First Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="admin-last-name-label">
                          Admin Last Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="lastName"
                          maxlength="50"
                          [(ngModel)]="lastName"
                          formControlName="lastName"
                          required
                        />

                        <div *ngIf="lastNameControl.touched && lastNameControl.errors">
                          <span
                            *ngIf="
                              lastNameControl.touched &&
                              (lastNameControl.hasError('required') || lastNameControl.hasError('lastName'))
                            "
                            class="text-danger"
                          >
                            A valid Last Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="admin-email-label">
                          Admin Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            id="adminEmail"
                            maxlength="100"
                            (blur)="checkEmailAlreadyExist()"
                            [(ngModel)]="email1"
                            formControlName="adminEmail"
                            required
                          />
                        </div>
                        <div *ngIf="emailControl.touched && emailControl.errors">
                          <span
                            *ngIf="
                              emailControl.touched &&
                              (emailControl.hasError('required') || emailControl.hasError('adminEmail'))
                            "
                            class="text-danger"
                          >
                            A valid Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['adminEmail'].touched &&
                            !registrationGroup.controls['adminEmail'].valid &&
                            !registrationGroup.controls['adminEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                        <div class="text-danger" *ngIf="emailMsg && !emailControl.hasError('required')">
                          Email Already Exist
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="phone-number-label">
                          Phone Number
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          formControlName="phoneNumber"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="phoneNumber"
                          autoClear="true"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="phoneNumberControl.touched && phoneNumberControl.errors">
                          <span
                            *ngIf="
                              phoneNumberControl.touched &&
                              (phoneNumberControl.hasError('required') || phoneNumberControl.hasError('phoneNumber'))
                            "
                            class="text-danger"
                          >
                            A valid Contact Number is required.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="custom-5">
                    <div class="form-group row">
                      <div class="custom-6">
                        <label data-cy="company-logo-label">
                          Company Logo
                          <span class="required" aria-required="true">*</span>
                        </label>
                        <span data-cy="company-logo-info" class="image-text">
                          Upload an image file with the extension of
                          <br />
                          JPG or PNG.
                          <br />
                          <br />
                          Your company logo will be displayed on the rep ID badges.
                          <br />
                          <br />
                          The image size limit is
                          <br />
                          8MB.
                        </span>
                      </div>
                      <div class="custom-6">
                        <div class="img-upload">
                          <div *ngIf="files.length == 0">
                            <img src="../../../assets/img/noimage.gif" alt="" />
                          </div>

                          <div>
                            <div *ngIf="files">
                              <div *ngFor="let file of files" class="mt-1">
                                <img *ngIf="file.imgSrc" [src]="file.imgSrc" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <input
                          type="file"
                          id="files"
                          [size]="8000000"
                          [withMeta]="true"
                          class="form-control-file"
                          name="file"
                          [allowedExt]="'(.png|.jpg|.jpeg)'"
                          formControlName="files"
                        />

                        <div *ngIf="fileCtrl.invalid && fileCtrl.dirty">
                          <div class="text-danger" *ngIf="fileCtrl.hasError('fileType')">Invalid File Type</div>
                        </div>

                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['files'].touched &&
                            !registrationGroup.controls['files'].valid &&
                            registrationGroup.controls['files'].errors.required
                          "
                        >
                          Required
                        </div>

                        <div *ngIf="fileCtrl.touched && fileCtrl.errors">
                          <span
                            *ngIf="fileCtrl.touched && (fileCtrl.hasError('required') || fileCtrl.hasError('files'))"
                            class="text-danger"
                          >
                            A Company Logo is required.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-form">
              <h2 data-cy="billing-contact-section-title">Billing contact</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row for-checkbox">
                      <div class="custom-3">
                        <input type="checkbox" (change)="fieldSameAsProvider($event)" value="one" name="one" />
                      </div>

                      <div class="custom-9">
                        <label data-cy="same-as-provider-admin-label">
                          Same as Provider admin
                          <span aria-required="true"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="billing-contact-name-label">
                          Billing Contact Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="billingContactName"
                          [(ngModel)]="billingContactName"
                          formControlName="billingContactName"
                          required
                        />

                        <div *ngIf="biilingContactNameControl.touched && biilingContactNameControl.errors">
                          <span
                            *ngIf="
                              biilingContactNameControl.touched &&
                              (biilingContactNameControl.hasError('required') ||
                                biilingContactNameControl.hasError('billingContactName'))
                            "
                            class="text-danger"
                          >
                            A valid Billing Contact Name is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="billing-email-label">
                          Billing Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            maxlength="100"
                            id="billingEmail"
                            [(ngModel)]="billingEmail"
                            formControlName="billingEmail"
                            required
                          />
                        </div>
                        <div *ngIf="billingEmailControl.touched && billingEmailControl.errors">
                          <span
                            *ngIf="
                              billingEmailControl.touched &&
                              (billingEmailControl.hasError('required') || billingEmailControl.hasError('billingEmail'))
                            "
                            class="text-danger"
                          >
                            A valid Billing Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['billingEmail'].touched &&
                            !registrationGroup.controls['billingEmail'].valid &&
                            !registrationGroup.controls['billingEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="billing-phone-label">
                          Billing Phone
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          formControlName="billingPhone"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="billingPhone"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="billingContactControl.touched && billingContactControl.errors">
                          <span
                            *ngIf="
                              billingContactControl.touched &&
                              (billingContactControl.hasError('required') ||
                                billingContactControl.hasError('billingPhone'))
                            "
                            class="text-danger"
                          >
                            A valid Billing Phone Number is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="street-address-line-1-label">
                          Street Address Line 1
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="billingStreetAddress"
                          [(ngModel)]="billingStreetAddress"
                          formControlName="billingStreetAddress"
                          required
                        />

                        <div *ngIf="biilingStreetAddressControl.touched && biilingStreetAddressControl.errors">
                          <span
                            *ngIf="
                              biilingStreetAddressControl.touched &&
                              (biilingStreetAddressControl.hasError('required') ||
                                biilingStreetAddressControl.hasError('billingStreetAddress'))
                            "
                            class="text-danger"
                          >
                            A valid Street Address Line 1 is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="street-address-line-2-label">
                          Street Address Line 2
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="billingStreetAddress2"
                          [(ngModel)]="billingStreetAddress2"
                          formControlName="billingStreetAddress2"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="city-label">
                          City
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="billingCity"
                          [(ngModel)]="billingCity"
                          formControlName="billingCity"
                          required
                        />

                        <div *ngIf="billingCityControl.touched && billingCityControl.errors">
                          <span
                            *ngIf="
                              billingCityControl.touched &&
                              (billingCityControl.hasError('required') || billingCityControl.hasError('billingCity'))
                            "
                            class="text-danger"
                          >
                            A valid City is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="state-label" for="fieldGroupType">
                          State
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="stateDropDownModel"
                          [(ngModel)]="billingState"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="billingState"
                          [filter]="true"
                        ></p-dropdown>

                        <div *ngIf="billingStateControl.touched && billingStateControl.errors">
                          <span
                            *ngIf="
                              billingStateControl.touched &&
                              (billingStateControl.hasError('required') || billingStateControl.hasError('billingState'))
                            "
                            class="text-danger"
                          >
                            A valid State is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="country-label" for="fieldGroupType">
                          Country
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-dropdown
                          [options]="countryDropDownModel"
                          (onChange)="resetZipBillingCodeMsg($event)"
                          [(ngModel)]="billingCountry"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="billingCountry"
                          [filter]="true"
                        ></p-dropdown>

                        <div *ngIf="billingCountryControl.touched && billingCountryControl.errors">
                          <span
                            *ngIf="
                              billingCountryControl.touched &&
                              (billingCountryControl.hasError('required') ||
                                billingCountryControl.hasError('billingCountry'))
                            "
                            class="text-danger"
                          >
                            A valid Country is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="billingCountry == 'USA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="99999?-9999"
                          formControlName="billingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurBillingZipCode()"
                          [(ngModel)]="billingZipCode"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="billingZipCodeControl.touched && billingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipBillingCodeMsg &&
                              billingZipCodeControl.touched &&
                              (billingZipCodeControl.hasError('required') ||
                                billingZipCodeControl.hasError('billingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipBillingCodeMsg" class="text-danger">
                            Zip Code must be at least 5 characters
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="billingCountry == 'CANADA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="***-***"
                          formControlName="billingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurBillingZipCode()"
                          [(ngModel)]="billingZipCode"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="6"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="billingZipCodeControl.touched && billingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipBillingCanMsg &&
                              billingZipCodeControl.touched &&
                              (billingZipCodeControl.hasError('required') ||
                                billingZipCodeControl.hasError('billingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipBillingCanMsg" class="text-danger">
                            Zip Code must be at least 6 characters
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sep-form">
              <h2 data-cy="shipping-contact-section-title">Shipping contact</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row for-checkbox">
                      <div class="custom-3">
                        <input type="checkbox" (change)="fieldSameAsBilling($event)" value="two" name="two" />
                      </div>

                      <div class="custom-9">
                        <label data-cy="same-as-billing-label">
                          Same as billing
                          <span aria-required="true"></span>
                        </label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="shipping-contact-name-label">
                          Shipping Contact Name
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="shippingContactName"
                          [(ngModel)]="shippingContactName"
                          formControlName="shippingContactName"
                          required
                        />

                        <div *ngIf="shippingContactNameControl.touched && shippingContactNameControl.errors">
                          <span
                            *ngIf="
                              shippingContactNameControl.touched &&
                              (shippingContactNameControl.hasError('required') ||
                                shippingContactNameControl.hasError('shippingContactName'))
                            "
                            class="text-danger"
                          >
                            A valid Shipping Contact Name is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="shipping-street-address-line-1-label">
                          Street Address Line 1
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          maxlength="50"
                          id="shippingStreetAddress"
                          [(ngModel)]="shippingStreetAddress"
                          formControlName="shippingStreetAddress"
                          required
                        />

                        <div *ngIf="shippingStreetAddressControl.touched && shippingStreetAddressControl.errors">
                          <span
                            *ngIf="
                              shippingStreetAddressControl.touched &&
                              (shippingStreetAddressControl.hasError('required') ||
                                shippingStreetAddressControl.hasError('shippingStreetAddress'))
                            "
                            class="text-danger"
                          >
                            A valid Street Address Line 1 is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="shipping-street-address-line-2-label">
                          Street Address Line 2
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          class="form-control rounded-0"
                          maxlength="50"
                          id="shippingStreetAddress2"
                          [(ngModel)]="shippingStreetAddress2"
                          formControlName="shippingStreetAddress2"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="shipping-city-label">
                          City
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          class="form-control rounded-0"
                          id="shippingCity"
                          maxlength="50"
                          [(ngModel)]="shippingCity"
                          formControlName="shippingCity"
                          required
                        />

                        <div *ngIf="shippingCityControl.touched && shippingCityControl.errors">
                          <span
                            *ngIf="
                              shippingCityControl.touched &&
                              (shippingCityControl.hasError('required') || shippingCityControl.hasError('shippingCity'))
                            "
                            class="text-danger"
                          >
                            A valid City is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="shipping-state-label" for="fieldGroupType">
                          State
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="stateDropDownModel"
                          [(ngModel)]="shippingState"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="shippingState"
                          [filter]="true"
                        ></p-dropdown>

                        <div *ngIf="shippingStateControl.touched && shippingStateControl.errors">
                          <span
                            *ngIf="
                              shippingStateControl.touched &&
                              (shippingStateControl.hasError('required') ||
                                shippingStateControl.hasError('shippingState'))
                            "
                            class="text-danger"
                          >
                            A valid State is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="shipping-country-label" for="fieldGroupType">
                          Country
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [options]="countryDropDownModel"
                          (onChange)="resetZipShippingCodeMsg($event)"
                          [(ngModel)]="shippingCountry"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="shippingCountry"
                          [filter]="true"
                        ></p-dropdown>

                        <div *ngIf="shippingCountryControl.touched && shippingCountryControl.errors">
                          <span
                            *ngIf="
                              shippingCountryControl.touched &&
                              (shippingCountryControl.hasError('required') ||
                                shippingCountryControl.hasError('shippingCountry'))
                            "
                            class="text-danger"
                          >
                            A valid Country is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="shippingCountry == 'USA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="99999?-9999"
                          formControlName="shippingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurShippingZipCode()"
                          [(ngModel)]="shippingZipCode"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="shippingZipCodeControl.touched && shippingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipShippingCodeMsg &&
                              shippingZipCodeControl.touched &&
                              (shippingZipCodeControl.hasError('required') ||
                                shippingZipCodeControl.hasError('shippingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipShippingCodeMsg" class="text-danger">
                            Zip Code must be at least 5 characters
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="shippingCountry == 'CANADA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-inputMask
                          mask="***-***"
                          formControlName="shippingZipCode"
                          slotChar=" "
                          (onBlur)="onBlurShippingZipCode()"
                          [(ngModel)]="shippingZipCode"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="6"
                          required="true"
                        ></p-inputMask>

                        <div *ngIf="shippingZipCodeControl.touched && shippingZipCodeControl.errors">
                          <span
                            *ngIf="
                              !zipShippingCanMsg &&
                              shippingZipCodeControl.touched &&
                              (shippingZipCodeControl.hasError('required') ||
                                shippingZipCodeControl.hasError('shippingZipCode'))
                            "
                            class="text-danger"
                          >
                            A valid Zip Code is required.
                          </span>

                          <span *ngIf="zipShippingCanMsg" class="text-danger">
                            Zip Code must be at least 6 characters
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-form">
              <h2 data-cy="technical-contact-section-title">Technical Contact</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="support-contact-email-label">
                          Support Contact Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>

                          <input
                            class="form-control rounded-0"
                            id="supportContactEmail"
                            maxlength="100"
                            formControlName="supportContactEmail"
                            required
                          />
                        </div>
                        <span data-cy="support-contact-email-info" style="font-size: 10px">
                          This is the email address your Field Reps will use to contact your support team. This is shown
                          to them on the support page of the app.
                        </span>
                        <div *ngIf="supportContactEmailControl.touched && supportContactEmailControl.errors">
                          <span
                            *ngIf="
                              supportContactEmailControl.touched &&
                              (supportContactEmailControl.hasError('required') ||
                                supportContactEmailControl.hasError('supportContactEmail'))
                            "
                            class="text-danger"
                          >
                            A valid Support Contact Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['supportContactEmail'].touched &&
                            !registrationGroup.controls['supportContactEmail'].valid &&
                            !registrationGroup.controls['supportContactEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="support-contact-phone-label">
                          Support Contact Phone
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          formControlName="supportPhone"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="supportPhone"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <span data-cy="support-contact-phone-info" style="font-size: 10px">
                          This is the Phone Number Field Reps will use to contact your support team. This is shown to
                          them on the support page of the app.
                        </span>

                        <div *ngIf="supportContactNumberControl.touched && supportContactNumberControl.errors">
                          <span
                            *ngIf="
                              supportContactNumberControl.touched &&
                              (supportContactNumberControl.hasError('required') ||
                                supportContactNumberControl.hasError('supportPhone'))
                            "
                            class="text-danger"
                          >
                            A valid Support Contact Phone Number is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="support-contact-note-label">
                          Support Contact Note
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <!-- <input type="text" class="form-control rounded-0" id="supportContactNote" formControlName="supportContactNote" /> -->
                        <textarea
                          class="form-control rounded-0"
                          maxlength="255"
                          id="supportContactNote"
                          formControlName="supportContactNote"
                        ></textarea>
                        <span data-cy="support-contact-note-info" style="font-size: 10px">
                          This is shown to the Field Rep on the support page of the app. This note is often used to tell
                          your users to call their manager before the support line and/or to show the support hours.
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="technical-contact-email-label">
                          Technical Contact Email
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <div class="input-group">
                          <span class="input-group-addon">
                            <i class="fa fa-envelope-o"></i>
                          </span>
                          <input
                            class="form-control rounded-0"
                            id="technicalContactEmail"
                            maxlength="100"
                            formControlName="technicalContactEmail"
                            required
                          />
                        </div>

                        <span data-cy="technical-contact-email-info" style="font-size: 10px">
                          This is the email address of the technical lead for this program.
                        </span>
                        <div *ngIf="technicalContactEmailControl.touched && technicalContactEmailControl.errors">
                          <span
                            *ngIf="
                              technicalContactEmailControl.touched &&
                              (technicalContactEmailControl.hasError('required') ||
                                technicalContactEmailControl.hasError('technicalContactEmail'))
                            "
                            class="text-danger"
                          >
                            A valid Technical Contact Email is required.
                          </span>
                        </div>
                        <div
                          class="text-danger"
                          *ngIf="
                            registrationGroup.controls['technicalContactEmail'].touched &&
                            !registrationGroup.controls['technicalContactEmail'].valid &&
                            !registrationGroup.controls['technicalContactEmail'].errors.required
                          "
                        >
                          Not a valid email
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="technical-contact-phone-label">
                          Technical Contact Phone
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <p-inputMask
                          formControlName="technicalPhone"
                          mask="(999) 999-9999? x99999"
                          [(ngModel)]="technicalPhone"
                          minlength="10"
                          (onBlur)="onBlurPhoneNumber()"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          required="true"
                        ></p-inputMask>

                        <span data-cy="technical-contact-phone-info" style="font-size: 10px">
                          This is the phone number of the technical lead for this program.
                        </span>

                        <div *ngIf="technicalContactNumberControl.touched && technicalContactNumberControl.errors">
                          <span
                            *ngIf="
                              technicalContactNumberControl.touched &&
                              (technicalContactNumberControl.hasError('required') ||
                                technicalContactNumberControl.hasError('technicalPhone'))
                            "
                            class="text-danger"
                          >
                            A valid Technical Contact Phone Number is required.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-form">
              <h2 data-cy="additional-details-section-title">Additional Details</h2>

              <div class="form-fields">
                <div class="row">
                  <div class="col-xl-7 col-sm-12">
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Do you have user-specific email
                          <br />
                          addresses for your reps?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          placeholder=" --Select-- "
                          [options]="yesNoDropDownModel"
                          optionLabel="label"
                          optionValue="value"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="userSpecificEmails"
                        ></p-dropdown>
                        <br />
                        <span style="font-size: 10px">Volt sends password reset emails directly to users</span>

                        <div
                          *ngIf="
                            userSpecificEmailForCommunicationControl.touched &&
                            userSpecificEmailForCommunicationControl.errors
                          "
                        >
                          <span
                            *ngIf="
                              userSpecificEmailForCommunicationControl.touched &&
                              (userSpecificEmailForCommunicationControl.hasError('required') ||
                                userSpecificEmailForCommunicationControl.hasError('userSpecificEmails'))
                            "
                            class="text-danger"
                          >
                            This field is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label>
                          Do you provide your employees
                          <br />
                          with a badge that contains
                          <br />
                          a picture of the user?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          placeholder=" --Select-- "
                          [options]="yesNoDropDownModel"
                          optionLabel="label"
                          optionValue="value"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="provideBadgeWithPhoto"
                        ></p-dropdown>
                        <div *ngIf="provideBadgeWithPhotoControl.touched && provideBadgeWithPhotoControl.errors">
                          <span
                            *ngIf="
                              provideBadgeWithPhotoControl.touched &&
                              (provideBadgeWithPhotoControl.hasError('required') ||
                                provideBadgeWithPhotoControl.hasError('userSpecificEmails'))
                            "
                            class="text-danger"
                          >
                            This field is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="estimated-users-label">
                          Estimated # Users
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="100"
                          class="form-control rounded-0"
                          id="estimatedUser"
                          formControlName="estimatedUser"
                          required
                        />
                        <div *ngIf="estimatedUserControl.touched && estimatedUserControl.errors">
                          <span
                            *ngIf="
                              estimatedUserControl.touched &&
                              (estimatedUserControl.hasError('required') ||
                                estimatedUserControl.hasError('estimatedUser'))
                            "
                            class="text-danger"
                          >
                            Estimated # Users is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="are-users-directly-employed-label">
                          Are Users Directly Employed?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="userEmployed"
                          formControlName="userEmployed"
                          required
                        />
                        <div *ngIf="userEmployedControl.touched && userEmployedControl.errors">
                          <span
                            *ngIf="
                              userEmployedControl.touched &&
                              (userEmployedControl.hasError('required') || userEmployedControl.hasError('userEmployed'))
                            "
                            class="text-danger"
                          >
                            Are Users Directly Employed? is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="are-users-mobile-enabled-label">
                          Are Users Mobile Enabled?
                          <span class="required" aria-required="true">*</span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="userMobileEnabled"
                          formControlName="userMobileEnabled"
                          required
                        />
                        <div *ngIf="userMobileEnabledControl.touched && userMobileEnabledControl.errors">
                          <span
                            *ngIf="
                              userMobileEnabledControl.touched &&
                              (userMobileEnabledControl.hasError('required') ||
                                userMobileEnabledControl.hasError('userMobileEnabled'))
                            "
                            class="text-danger"
                          >
                            Are Users Mobile Enabled? is required.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="corporate-website-label">
                          Corporate Website
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="userCorporateWebsite"
                          formControlName="userCorporateWebsite"
                        />
                        <div *ngIf="corporateWebsiteControl.touched && corporateWebsiteControl.errors">
                          <!-- <span *ngIf="corporateWebsiteControl.touched && (corporateWebsiteControl.hasError('required') || corporateWebsiteControl.hasError('userCorporateWebsite'))" class="text-danger">Website is required. </span> -->
                          <span
                            *ngIf="
                              corporateWebsiteControl.touched &&
                              corporateWebsiteControl.invalid &&
                              !corporateWebsiteControl.hasError('required')
                            "
                            class="text-danger"
                          >
                            A vaild corporate website is required
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="corporate-address-1-label">
                          Corporate Address 1
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="userAddressLine1"
                          formControlName="userAddressLine1"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="corporate-address-2-label">
                          Corporate Address 2
                          <span aria-required="true"></span>
                        </label>
                      </div>

                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="userAddressLine2"
                          formControlName="userAddressLine2"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="additional-details-city-label">
                          City
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <input
                          type="text"
                          maxlength="50"
                          class="form-control rounded-0"
                          id="userCity"
                          formControlName="userCity"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="additional-details-state-label" for="fieldGroupType">
                          State
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [filter]="true"
                          [options]="stateDropDownModel"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="userState"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="custom-3">
                        <label data-cy="additional-details-country-label" for="fieldGroupType">
                          Country
                          <span class="required" aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-dropdown
                          [filter]="true"
                          [options]="countryDropDownModel"
                          (onChange)="resetZipUserCodeMsg($event)"
                          [(ngModel)]="userCountry"
                          [style]="{ width: '200px', 'margin-left': '15px' }"
                          formControlName="userCountry"
                        ></p-dropdown>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="userCountry == 'USA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="99999?-9999"
                          formControlName="userZipCode"
                          slotChar=" "
                          (onBlur)="onBlurUserZipCode()"
                          [(ngModel)]="userZipCode"
                          (keypress)="isNumberKey($event)"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                        ></p-inputMask>

                        <div *ngIf="userZipCodeControl.touched && userZipCodeControl.errors">
                          <span *ngIf="zipUserCodeMsg" class="text-danger">Zip Code must be at least 5 characters</span>
                        </div>
                      </div>
                    </div>

                    <div class="form-group row" *ngIf="userCountry == 'CANADA'">
                      <div class="custom-3">
                        <label>
                          Zip Code
                          <span aria-required="true"></span>
                        </label>
                      </div>
                      <div class="custom-9">
                        <p-inputMask
                          mask="***-***"
                          formControlName="userZipCode"
                          slotChar=" "
                          (onBlur)="onBlurUserZipCode()"
                          [(ngModel)]="userZipCode"
                          styleClass="myclass"
                          class="form-control custom-input"
                          minlength="5"
                        ></p-inputMask>

                        <div *ngIf="userZipCodeControl.touched && userZipCodeControl.errors">
                          <span *ngIf="zipUserCanMsg" class="text-danger">Zip Code must be at least 6 characters</span>
                        </div>
                      </div>
                    </div>

                    <div class="submit-btn" style="text-align: center">
                      <button data-cy="submit-button" type="submit" class="btn btn-primary">Submit</button>

                      <button data-cy="cancel-button" type="button" (click)="formReset()" class="btn btn-primary">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</v-loading-container>
